export interface Login {
  user: User;
  accessToken: string;
  refreshToken: string;
  subscriptionInfo: SubscriptionInfo;
}

export interface User {
  id: string | number;
  nickName: string;
  email: string;
  deviceId: string;
  gender: string | null;
  type: string;
  createdAt: string;
  birthday: string | null;
  userStatus: string;
  termsAgreed: boolean;
  currentSubscription: boolean;
}

export interface SubscriptionInfo {
  startDate: string | null;
  endDate: string | null;
  price: number | null;
  name: Language<string>;
}

export interface SubscriptionUserInfo {
  marketingAgreed: Boolean;
  termsAgreed: Boolean;
  nickName: string | null;
}

export type CategoryFilter = {
  value: Language<string>;
  code: number;
};

export type Mood = '행복' | '평온' | '모름' | '피곤함' | '우울함' | '슬픔' | '화남' | '아픔';

export type Diary = {
  id: number;
  userId: User['id'];
  emotionType: string;
  oneLine: string;
  date: string;
  createdAt: string;
  updatedAt: string;
};

export type Album = {
  albumId: number;
  albumName: string;
  createdAt: string;
  updatedAt: string;
  thumbnailList: string[];
  tracks: any[];
  total: number;
};

export type Account = {
  nickName: string;
  email: string;
  type: string;
  productName: Language<string>;
  isCoupon: boolean;
};

export type SeasonTicket = {
  type: string;
  startDay: string;
  endDay: string;
  status: string;
  payment_amount: number;
};

export type Notice = {
  idx: number;
  writer: string;
  title: string;
  content: string[];
  createdAt: string;
  updatedAt: string;
  status: string;
};

export type Inquiry = {
  id: number;
  userId: User['id'];
  title: string;
  content: string;
  answer: string | null;
  createdAt: string;
};

export type Situation = {
  id: number;
  name: Language<string>;
  artwork: string;
  description: Language<string>;
  tag?: Language<string[]>;
};
export type Comment = {
  id: string | number;
  createdAt: string;
  updatedAt: string;
  nickName: string;
  comment: string;
  reported: boolean;
  userId: User['id'];
};
export type Coupon = {
  name: string;
  code: string;
  type: string;
  value: number;
  registeredAt: string;
  usedAt: string;
  status: COUPONSTATUS;
};

export type CouponAvailable = {
  id: number;
  code: string;
  name: string;
  type: string;
  value: number;
  status: COUPONSTATUS;
  expiredAt: string;
};

export enum COUPONSTATUS {
  UNASSIGNED = 'unassigned',
  REGISTERED = 'registered',
  USED = 'used',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type Track = {
  id: number;
  artwork?: string;
  thumbnail: string;
  category: Language<string>;
  createdAt: string;
  duration: number;
  isFree: boolean;
  likeCount: number;
  url?: string;
  title: Language<string>;
  tag?: Language<string[]>;
  description?: Language<string>;
  isLiked?: boolean;
};
export type Language<T> = {
  /** 한국어 */
  ko: T;
  /** 영어 */
  en: T;
  /** 스페인어 */
  es: T;
  /** 독일어 */
  de: T;
  /** 프랑스어 */
  fr: T;
  /** 포르투갈어 */
  pt: T;
  /** 러시아어 */
  ru: T;
  /** 일본어 */
  ja: T;
  /** 중국어 */
  zh: T;
};

export type Statistics = {
  longest: number;
  totalDays: number;
  totalDuration: number;
  category: Language<string>;
};

export type CategoryInfo = {
  id: number;
  video: string;
  thumbnail: string;
  createdAt: string;
  dlYn: string;
  viewYn: string;
  updatedAt: string;
  deletedAt: any;
  category: Language<string>;
  effectiveUsage: Language<string>;
  effectIntroduction: Language<string>;
  doctors: Doctor[];
  totalDoctors: number;
};

export type Doctor = {
  id: number;
  image: string;
  dlYn: string;
  viewYn: string;
  name: Language<string>;
  position: Language<string>;
  department: Language<string>;
  history: Language<string>;
  description: Language<string>;
  categories: Categories;
};
export interface Categories {
  list: Language<string>[];
  total: number;
}

export interface Theme {
  id: number;
  artwork: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  dlYn: string;
  viewYn: string;
  title: Language<string>;
}

export type PaymentMethod = {
  isCard: boolean;
  cardName: string;
  customerUid: string;
};

export type Product = {
  id: number;
  price: number;
  priceUSD: number;
  duration: number;
  isSubscriptionProduct: boolean;
  name: Language<string>;
  description: Language<string>;
};

export type SearchMain = {
  keywords: Keyword[];
  tracks: Track[];
};
export type Keyword = {
  keyword: string;
};

export type TrackDetail = {
  categoryInfo: TrackDetailCategoryInfo;
  title: Language<string>;
  category: Language<string>;
  tag: Language<string[]>;
  description: Language<string>;
};
export interface TrackDetailCategoryInfo {
  id: number;
  effectiveUsage: Language<string>;
  effectIntroduction: Language<string>;
  doctors: Doctor[];
  totalDoctors: number;
}

export interface Receipt {
  orderNumber: number;
  totalPayment: number;
  orderDate: string;
  orderProductId: number;
  orderProductName: string;
  impUid: string;
  state: string;
}

export interface GiftHistory {
  createdAt: string;
  status: string;
  code: string;
  name: Language<string>;
}
export interface Tag {
  tag: string;
  tagCount: number;
}
export interface TagKo {
  tagKo: string;
  tagCount: number;
}
export interface TagEn {
  tagEn: string;
  tagCount: number;
}

export interface ResponseType<T> {
  list: T[];
  total: number;
}

export type SurveyResult = {
  category: 'appetite' | 'stress' | 'sleep' | 'cognition' | 'pain' | 'skin';
  prob: number;
  rank: 'A' | 'B' | 'C';
}[];

export type AIRecommendedTracks = {
  category: 'appetite' | 'stress' | 'sleep' | 'concentration';
  id: string;
  thumbnail: string;
  title: {
    en: string;
    ko: string;
  };
  description: {
    en: string;
    ko: string;
  };
  tag: {
    en: string;
    ko: string;
  };
  artwork: string;
  url: string;
};

export type AISurveyResultResponse = {
  mostCategory: 'appetite' | 'stress' | 'sleep' | 'cognition' | 'pain' | 'skin';
  recommendedTracks: AIRecommendedTracks[];
  surveyResult: SurveyResult;
};

export enum FirebaseAnalyticsEvent {
  SIGNUP = 'signup',
  USE_COUPON = 'use_coupon',
  LOGIN = 'login',
}

export enum ReactAppEnv {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  LOCAL = 'local',
}

export interface FirebaseAnalyticsParam {
  user_uuid?: User['id'];
  login_platform?: User['type'];
  signup_platform?: User['type'];
  coupon_code?: Coupon['code'];
  environment?: ReactAppEnv;
  is_db_life_user?: boolean;
}

export enum UserSubscriptionStatusEnum {
  Active = 'active',
  Paused = 'paused',
  Canceled = 'canceled',
  GracePeriod = 'grace_period',
  Hold = 'hold',
  Restarted = 'restarted',
  Expired = 'expired',

  /**
   * 이미 진행 중인 구독이 있는데
   * 주문이 이루어지거나, 쿠폰을 사용해서 구독권이 생기는 경우
   *
   * @todo
   * Pending 은 store 에서 결제를 기다리고 있는 상태를 가리켜서 구분.
   * 주문 상태와 구독 상태를 분리할 필요.
   */
  Queued = 'queued',
}
